import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const BackButton = styled.button`
    background: transparent;
    border: 0em;
    color: #f0eded;
    padding: 0.75em 1em;
    font-size: 2em;
    box-sizing: border-box;
    justify-content: start;
`;

const aboutStyle = {
    color: "#f0eded",
    fontSize: "1.5em",
};
const backStyle = {
    display: "grid",
    justifyContent: "end",
    boxSizing: "border-box",
};

const About = () => {
    let navigate = useNavigate();
    const rootStyle = {
        display: "grid",
        boxSizing: "border-box",
    };
    return (
        <div label="AboutRoot" style={rootStyle}>
            <div label="BackButton" style={backStyle}>
                <BackButton onClick={() => navigate("/")}>Back</BackButton>
            </div>
            <div label="About" style={aboutStyle}>
                Dune is the best selling science fiction novel of all time. And
                yet, somehow, there is no <b>unabridged</b> audiobook for Dune.
            </div>
        </div>
    );
};

export default About;
