import React, { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import styled from "styled-components";

const AboutButton = styled.button`
    background: transparent;
    border: 0em;
    box-sizing: border-box;
    color: #f0eded;
    font-size: 2em;
    justify-content: end;
    padding: 0.75em 1em;
`;
const DuneImage = styled.img`
    align-items: center;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    max-height: 70vh;
    padding-top: 0em;
    padding-bottom: 2em;
`;
const Upload = styled.input`
    align-items: center;
    background: transparent;
    border: 0.25em solid royalblue;
    border-radius: 3px;
    box-sizing: border-box;
    color: #f0eded;
    cursor: default;
    display: flex;
    font-size: 2em;
    height: 2.25em;
    justify-content: center;
    margin: 0.25em 0.25em 0.25em 0.25em;
    padding: 0.25em 1em;
    width: 50%;
`;
const UploadButton = styled.button`
    align-items: center;
    background: transparent;
    border: 0.25em solid royalblue;
    border-radius: 3px;
    box-sizing: border-box;
    color: #f0eded;
    display: flex;
    font-size: 2em;
    height: 2.25em;
    justify-content: center;
    margin: 0.25em 0.25em 0.25em 0em;
    padding: 0.25em 1em;
    width: 50%;
`;

const aboutStyle = {
    boxSizing: "border-box",
    display: "grid",
    justifyContent: "end",
};
const duneCoverStyle = {
    boxSizing: "border-box",
    display: "grid",
    justifyContent: "center",
};
const fileMetadataStyle = {
    alignItems: "center",
    boxSizing: "border-box",
    color: "#f0eded",
    display: "flex",
    fontSize: "2em",
    justifyContent: "center",
};
const rootStyle = {
    boxSizing: "border-box",
};
const uploadAndMetadataStyle = {
    alignItems: "center",
    boxSizing: "border-box",
    display: "grid",
    justifyContent: "center",
};
const uploadStyle = {
    alignItems: "center",
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "center",
    maxWidth: "100%",
};

const DuneSynthesis = (props) => {
    const [state, setState] = useState({
        audioSource: null,
        isFileSelected: false,
        selectedFile: "",
        uploadBoxValue: "Select Dune file...",
        urlRedirect: null,
    });
    let hiddenFileInput = React.createRef();
    let navigate = useNavigate();
    console.log("EbookSynthesis fileUploadUrl: " + props.fileUploadUrl);

    const handleEbookUploadClick = () => {
        hiddenFileInput.current.click();
    };

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile === undefined) {
            return;
        }
        setState({
            isFileSelected: true,
            selectedFile: selectedFile,
            uploadBoxValue:
                selectedFile.name.length > 80
                    ? selectedFile.name.substring(0, 80) + "..."
                    : selectedFile.name,
            urlRedirect: state.urlRedirect,
        });
    };

    const handleFileSubmission = () => {
        const fileUploadUrl = props.fileUploadUrl + "/synthesisDuneEbook";
        console.log(
            "handle submission called, uploading file: '" +
                state.selectedFile +
                "' named: '" +
                state.selectedFile.name +
                "' to url: " +
                fileUploadUrl
        );
        const formData = new FormData();
        formData.append("file", state.selectedFile);
        fetch(fileUploadUrl, {
            method: "POST",
            body: formData,
        })
            .then((response) => response.json())
            .then((result) => {
                console.log("Success:", result);
                console.log("Request was:", formData);
                console.log("Redirecting to: ", result.download_url);
                setState({
                    isFileSelected: state.isFileSelected,
                    selectedFile: state.selectedFile,
                    uploadBoxValue: state.uploadBoxValue,
                    urlRedirect: result.download_url,
                });
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    var audioPlayer = "";
    console.log("state.audioSource: " + state.audioSource);
    if (state.audioSource != null) {
        console.log("Creating audio tag.....");
        const blobUrl = URL.createObjectURL(state.audioSource);
        console.log("blobUrl: " + blobUrl);

        audioPlayer = <audio controls src={blobUrl} type="audio/mpeg" />;
    }
    return (
        <div label="DuneSynthesisRoot" style={rootStyle}>
            {state.urlRedirect && <Navigate to={state.urlRedirect} />}
            <div label="AboutButton" style={aboutStyle}>
                <AboutButton onClick={() => navigate("/about")}>
                    About
                </AboutButton>
            </div>
            <div label="DuneCover" style={duneCoverStyle}>
                <DuneImage
                    src="/dune-cover--no-background--cropped.png"
                    alt="Dune Paperback Cover (Present Day)"
                ></DuneImage>
            </div>
            <div
                label="DuneUploadAndEbookMetadata"
                style={uploadAndMetadataStyle}
            >
                <div label="DuneUpload" style={uploadStyle}>
                    <input
                        type="file"
                        name="file"
                        ref={hiddenFileInput}
                        onChange={handleFileChange}
                        style={{ display: "none" }}
                    />
                    <Upload
                        onClick={handleEbookUploadClick}
                        onChange={handleFileChange}
                        onMouseOver={() => ({})}
                        value={state.uploadBoxValue}
                    />
                    <UploadButton onClick={handleFileSubmission}>
                        Synthesize
                    </UploadButton>
                </div>
                <div label="DuneEbookMetadata" style={fileMetadataStyle}>
                    <FileMetadata
                        isFileSelected={state.isFileSelected}
                        selectedFile={state.selectedFile}
                    ></FileMetadata>
                </div>
                <div label="DuneSample"></div>
            </div>
        </div>
    );
};

function FileMetadata(props) {
    const isFileSelected = props.isFileSelected;
    const selectedFile = props.selectedFile;
    const style = {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
    };
    if (!isFileSelected) {
        return <div style={style}></div>;
    }
    return (
        <div style={style}>
            <p>
                Filetype: {selectedFile.type}
                <br></br>
                Size in bytes: {selectedFile.size}
            </p>
        </div>
    );
}

DuneSynthesis.propTypes = {
    fileUploadUrl: PropTypes.string.isRequired,
};

export default DuneSynthesis;
