import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import styled from "styled-components";

const BackButton = styled.button`
    background: transparent;
    border: 0em;
    color: #f0eded;
    padding: 0.75em 1em;
    font-size: 2em;
    box-sizing: border-box;
    justify-content: start;
`;
const DownloadButton = styled.button`
    border: 0em;
    background: royalblue;
    color: #f0eded;
    padding: 0.75em 1em;
    font-size: 2em;
    box-sizing: border-box;
    justify-content: center;
    font-color: "black";
`;
const DuneImage = styled.img`
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;

    max-height: 70vh;
    margin-left: auto;
    margin-right: auto;
    padding-top: 0em;
    padding-bottom: 2em;
`;
const FileStatusHeading = styled.h1`
    color: royalblue;
    margin: 0.5em 0 0.5em 0.5em;
`;

const backStyle = {
    display: "grid",
    justifyContent: "end",
    boxSizing: "border-box",
};
const downloadStyle = {
    display: "grid",
    padding: "2em",
    justifyContent: "center",
    boxSizing: "border-box",
};
const duneCoverStyle = {
    display: "grid",
    justifyContent: "center",
    boxSizing: "border-box",
};
const fileStatusStyle = {
    color: "#f0eded",
};

const DuneDownload = (props) => {
    console.log("DuneDownload");
    let { _synthesis_type, synthesis_id } = useParams();
    const fileCheckUrl =
        props.apiUrl + "/synthesisCheckForFile/ebook/" + synthesis_id;
    console.log("synthesis_id: " + synthesis_id);

    // TODO: Make file type dynamic?
    const downloadFileName = synthesis_id + ".zip";
    console.log("downloadFileName: " + downloadFileName);

    let navigate = useNavigate();
    const [state, setState] = useState({
        duneIntervalId: null,
        fileDownloaded: false,
        fileDownloadUrl: "",
        synthesizedFileExists: false,
    });
    console.log(
        "Right before check state.synthesizedFileExists: '" +
            state.synthesizedFileExists +
            "' and state.fileDownloaded: '" +
            state.fileDownloaded +
            "'"
    );

    const resetState = () => {
        if (state.duneIntervalId !== null) {
            clearInterval(state.duneIntervalId);
        }
        setState({
            duneIntervalId: null,
            fileDownloaded: false,
            fileDownloadUrl: "",
            synthesizedFileExists: false,
        })
    };

    const handleDownloadButtonClick = () => {
        if (state.synthesizedFileExists && !state.fileDownloaded) {
            console.log(
                "File exists! See state.synthesizedFileExists: " +
                    state.synthesizedFileExists +
                    " Downloading from: '" +
                    state.fileDownloadUrl +
                    "' state.fileDownloaded: '" +
                    state.fileDownloaded +
                    "'"
            );
            setState({
                duneIntervalId: state.duneIntervalId,
                fileDownloaded: true,
                fileDownloadUrl: state.fileDownloadUrl,
                synthesizedFileExists: state.synthesizedFileExists,
            });
            window.location.href = state.fileDownloadUrl;
        } else {
            console.log("File does not exist!");
        }
    };

    
    var checkForFile = async () => {
        let response = await fetch(fileCheckUrl, {
            method: "GET",
            headers: { "Content-Type": "application/json" },
        });
        if (!response.ok) {
            console.error("Status was:", response.status);
            console.error("Error was:", response.error);
            console.error("Request url was:", fileCheckUrl);
        }
        let result = await response.json();
        console.log("Full response result: " + Object.entries(result));
        const synthesizedFileExists = JSON.parse(
            result.exists.toLowerCase()
        );
        const fileDownloadUrl = result.downloadUrl;
        console.log("File download url: " + fileDownloadUrl);
        if (state.synthesizedFileExists !== synthesizedFileExists) {
            setState({
                duneIntervalId: state.duneIntervalId,
                fileDownloaded: state.fileDownloaded,
                fileDownloadUrl: fileDownloadUrl,
                synthesizedFileExists: synthesizedFileExists,
            });
        }
        console.log(
            "synthesizedFileExists, after response: " +
                synthesizedFileExists
        );
        return synthesizedFileExists;
    };

    if (state.synthesizedFileExists !== true && state.duneIntervalId === null) {
        var intervalId = setInterval(async () => {
            await checkForFile();
            console.log("in interval: synthesizedFileExists: " + state.synthesizedFileExists);
            if (state.synthesizedFileExists === true) {
                console.log("In interval fn: Clearing interval.....");
                clearInterval(state.duneIntervalId);
                setState({
                    duneIntervalId: null,
                    fileDownloaded: state.fileDownloaded,
                    fileDownloadUrl: state.fileDownloadUrl,
                    synthesizedFileExists: state.synthesizedFileExists,
                })
            }
        }, 5000);
        setState({
            duneIntervalId: intervalId,
            fileDownloaded: state.fileDownloaded,
            fileDownloadUrl: state.fileDownloadUrl,
            synthesizedFileExists: state.synthesizedFileExists,
        });
    }

    let duneDownload = "";
    if (state.synthesizedFileExists) {
        duneDownload = (
            <div label="Download" style={downloadStyle}>
                <DownloadButton onClick={handleDownloadButtonClick}>
                    Download
                </DownloadButton>
            </div>
        );
    }

    return (
        <div label="DownloadPage">
            <div label="BackButton" style={backStyle}>
                <BackButton onClick={() => {
                    resetState();
                    navigate("/");
                }}>Back</BackButton>
            </div>
            {/* <PageTitle>Synthesis Download</PageTitle> */}
            <div label="DuneCover" style={duneCoverStyle}>
                <DuneImage
                    src="/dune-cover--no-background--cropped.png"
                    alt="Dune Paperback Cover (Present Day)"
                ></DuneImage>
            </div>
            <div label="FileStatus" style={fileStatusStyle}>
                <FileStatusHeading>
                    File synthesis complete?{" "}
                    {String(state.synthesizedFileExists)}
                </FileStatusHeading>
                You can safely save this URL and return later to retrieve your
                synthesized audiobook.
            </div>
            {duneDownload}
        </div>
    );
};

export default DuneDownload;
