import React from "react";
import { Route, Routes } from "react-router-dom";

import About from "./about";
import DuneDownload from "./dune_download";
import DuneSynthesis from "./dune_synthesis";

const Main = (props) => {
    console.log("props: " + Object.entries(props));
    console.log("props.url: " + props.url);
    return (
        <Routes>
            <Route
                path="/"
                element={<DuneSynthesis fileUploadUrl={props.url} />}
            ></Route>
            <Route path="/about" element={<About />}></Route>
            <Route
                path="/synthesisDownload/:synthesis_type/:synthesis_id"
                element={<DuneDownload apiUrl={props.url} />}
            ></Route>
        </Routes>
    );
};

export default Main;
